<template>
  <div>
    <bc-navbar-user />

    <b-container class="content-spacer-lg">
      <router-view />
    </b-container>

    <bc-footer-user />
  </div>
</template>

<script>
import bcNavbarUser from "./components/NavbarUser.vue";
import bcFooterUser from "./components/FooterUser.vue";

export default {
  name: "LayoutUser",
  components: {
    "bc-footer-user": bcFooterUser,
    "bc-navbar-user": bcNavbarUser,
  },
};
</script>
